export class FilterInputModel {
  name: string;
  varName: string;
  numberValue: boolean;
  multipleObject: boolean;
  multipleObjectValue: string;

  constructor(name: string, varName: string, numberValue: boolean,
              multipleObject: boolean, multipleObjectValue: string) {
    this.name = name;
    this.varName = varName;
    this.numberValue = numberValue;
    this.multipleObject = multipleObject;
    this.multipleObjectValue = multipleObjectValue;
  }
}
