import {Currency} from './currency.model';
import {CustomerType} from './customertype.model';
import {Depot} from './depot.model';
import {Frequencies} from './frequencies.model';
import {Prefix} from './prefix.model';
import {BankAccount} from './bank-account.model';
import {Country} from './country.model';

export class Customer {
  name: string;
  address_1: string;
  address_2: string;
  address_3: string;
  alternative_store_number: string;
  ar_store_number: string;
  bank_account_id: number;
  bank_account: BankAccount;
  billing_frequency_id: number;
  // billing_frequency: Frequencies;
  buyer_global_location_number: string;
  code: string;
  country_id: number;
  // country: Country;
  county: string;
  credit_note_reference_id: number;
  currency_id: number;
  // currency: Currency;
  customer_message: string;
  customer_notes: string;
  customer_reference: string;
  customer_type_id: number;
  // customer_type: CustomerType;
  date_added: string;
  delivery_global_location_number: string;
  delivery_only: boolean;
  depot_id: number;
  // depot: Depot;
  email: string;
  edi_package: string;
  edi_package_id: number;
  edi_profile_id: number;
  id: any;
  invoice_reference_id: number;
  // invoice_reference: Prefix;
  is_active: boolean;
  is_priced: boolean;
  master_customer_id: number;
  // master_customer: Customer;
  new_customer: boolean;
  opening_balance: number;
  phone: string;
  pod: boolean;
  postcode: string;
  process_credits_separately: boolean;
  restrict: boolean;
  sage_company_id: number;
  // sage_company: any;
  // supplier_global_location_number: string;
  town: string;

  constructor(
    name: string,
    address_1: string,
    address_2: string,
    address_3: string,
    alternative_store_number: string,
    ar_store_number: string,
    bank_account_id: number,
    billing_frequency_id: number,
    buyer_global_location_number: string,
    code: string,
    country_id: number,
    county: string,
    credit_note_reference_id: number,
    currency_id: number,
    customer_message: string,
    customer_notes: string,
    customer_reference: string,
    customer_type_id: number,
    date_added: string,
    delivery_global_location_number: string,
    delivery_only: boolean,
    depot_id: number,
    email: string,
    edi_package: string,
    edi_package_id: number,
    edi_profile_id: number,
    id: any,
    invoice_reference_id: number,
    is_active: boolean,
    is_priced: boolean,
    master_customer_id: number,
    new_customer: boolean,
    opening_balance: number,
    phone: string,
    pod: boolean,
    postcode: string,
    process_credits_separately: boolean,
    restrict: boolean,
    sage_company_id: number,
    // supplier_global_location_number: string,
    town: string
  ) {
    this.address_1 = address_1;
    this.address_2 = address_2;
    this.address_3 = address_3;
    this.alternative_store_number = alternative_store_number;
    this.ar_store_number = ar_store_number;
    this.bank_account_id = bank_account_id;
    this.billing_frequency_id = billing_frequency_id;
    this.buyer_global_location_number = buyer_global_location_number;
    this.code = code;
    this.county = county;
    this.country_id = country_id;
    this.credit_note_reference_id = credit_note_reference_id;
    this.currency_id = currency_id;
    this.customer_message = customer_message;
    this.customer_notes = customer_notes;
    this.customer_reference = customer_reference;
    this.customer_type_id = customer_type_id;
    this.date_added = date_added;
    this.delivery_global_location_number = delivery_global_location_number;
    this.delivery_only = delivery_only;
    this.depot_id = depot_id;
    this.email = email;
    this.edi_package = edi_package;
    this.edi_package_id = edi_package_id;
    this.edi_profile_id = edi_profile_id;
    this.id = id;
    this.invoice_reference_id = invoice_reference_id;
    this.is_active = is_active;
    this.is_priced = is_priced;
    this.master_customer_id = master_customer_id;
    this.name = name;
    this.new_customer = new_customer;
    this.opening_balance = opening_balance;
    this.phone = phone;
    this.pod = pod;
    this.postcode = postcode;
    this.process_credits_separately = process_credits_separately;
    this.restrict = restrict;
    // this.supplier_global_location_number = supplier_global_location_number;
    this.sage_company_id = sage_company_id;
    this.town = town;
  }
}
