import {Customer} from './customer.model';

export class SpecialTermsDiscount {
  id: number;
  customer_id: number;
  percentage: number;
  customer: Customer;
  is_active: boolean;

  constructor(id: number, customer_id: number, percentage: number, is_active: boolean) {
    this.id = id;
    this.percentage = percentage;
    this.customer_id = customer_id;
    this.is_active = is_active;
  }
}
