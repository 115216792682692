export class DropdownCreateFormItem {
  heading: string;
  valueToAccess: string;
  targetTable: string;
  sortBy: string;
  valueToTarget: string;
  columns: string;
  required: boolean;
  userInput: any;
  userChoice: any;
  specialCondition: string;
  emptyInputsAllowed: boolean;
  itIsAForeignKey: boolean;
  id: number;

  constructor(
    heading: string,
    valueToAccess: string,
    required: boolean,
    itIsAForeignKey: boolean,
    targetTable: string,
    sortBy: string,
    valueToTarget: string,
    columns: string,
    userInput?: string,
    userChoice?: any,
    emptyInputsAllowed?: boolean,
    specialCondition?: string) {
    this.valueToAccess = valueToAccess;
    this.targetTable = targetTable;
    this.sortBy = sortBy;
    this.valueToTarget = valueToTarget;
    this.columns = columns;
    this.heading = heading;
    this.required = required;
    if (userInput) {
      this.userInput = userInput;
    } else {
      this.userInput = '';
    }
    this.emptyInputsAllowed = this.emptyInputsAllowed ? true : false;
    this.itIsAForeignKey = itIsAForeignKey;
    if (userChoice) {
      this.userChoice = userChoice;
      this.id = userChoice.id;
    } else {
      this.userChoice = {id: null};
    }
    this.specialCondition = specialCondition ? specialCondition : '';
  }
}
