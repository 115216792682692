import {Customer} from './customer.model';

export class CustomerDiscount {
  id: number;
  customer_id: number;
  customer: Customer;
  percentage: number;
  product: Customer;
  effective_from: string;
  effective_to: string;

  constructor(id: number, customer_id: number, percentage: number, effective_from: string, effective_to: string) {
    this.id = id;
    this.percentage = percentage;
    this.customer_id = customer_id;
    this.effective_from = effective_from;
    this.effective_to = effective_to;
  }
}
