import {Currency} from './currency.model';
import {HhRoute} from './hhroute.model';
import {Customer} from './customer.model';
import {Country} from './country.model';
import {BankAccount} from './bank-account.model';

export class DeliveryAgent {
  name: string;
  address_1: string;
  address_2: string;
  address_3: string;
  county: string;
  currency_id: number;
  currency: Currency;
  email: string;
  id: any;
  customer_id: number;
  customer: Customer;
  phone: string;
  postcode: string;
  town: string;
  delivery_agent_commission_percentage: number;
  route_id: number;
  route: HhRoute;
  country_id: number;
  country: Country;
  bank_account: BankAccount;
  bank_account_id: number;

  constructor(
    name: string,
    address_1: string,
    address_2: string,
    address_3: string,
    county: string,
    currency_id: number,
    email: string,
    id: any,
    customer_id: number,
    phone: string,
    postcode: string,
    town: string,
    delivery_agent_commission_percentage: number,
    route_id: number,
    country_id: number,
    bank_account_id: number
  ) {
    this.address_1 = address_1;
    this.address_2 = address_2;
    this.address_3 = address_3;
    this.country_id = country_id;
    this.county = county;
    this.currency_id = currency_id;
    this.email = email;
    this.id = id;
    this.name = name;
    this.phone = phone;
    this.postcode = postcode;
    this.town = town;
    this.customer_id = customer_id;
    this.delivery_agent_commission_percentage = delivery_agent_commission_percentage;
    this.route_id = route_id;
    this.bank_account_id = bank_account_id;
  }
}
