import {Product} from './product.model';

export class ProductDiscount {
  id: number;
  product_id: number;
  percentage: number;
  product: Product;
  effective_from: string;
  effective_to: string;

  constructor(id: number, product_id: number, percentage: number, effective_from: string, effective_to: string) {
    this.id = id;
    this.percentage = percentage;
    this.product_id = product_id;
    this.effective_from = effective_from;
    this.effective_to = effective_to;
  }
}
