export class Currency {
  alpha_code: string;
  id: any;
  minor_unit: number;
  name: string;
  numeric_code: string;

  constructor(alpha_code: string, id: any, minor_unit: number, name: string, numeric_code: string) {
    this.alpha_code = alpha_code;
    this.id = id;
    this.minor_unit = minor_unit;
    this.name = name;
    this.numeric_code = numeric_code;
  }
}
