export class Quantifiers {
  description: string;
  id: any;
  name: string;

  constructor(description: string, id: any, name: string) {
    this.description = description;
    this.id = id;
    this.name = name;
  }
}
