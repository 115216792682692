import {Country} from './country.model';

export class Vatrate {
  id: any;
  name: string;
  rate: number;
  resale: boolean;
  country_id: number;
  country: Country;

  constructor(id: any,
              name: string,
              rate: number,
              resale: boolean,
              country_id: number) {
    this.id = id;
    this.name = name;
    this.rate = rate;
    this.resale = resale;
    this.country_id = country_id;
  }
}
