import {Product} from './product.model';
import {Vatrate} from './vatrate.model';
import {Country} from './country.model';
import {Currency} from './currency.model';

export class ProductPriceMapping {
  id: number;
  product_id: number;
  product: Product;
  price: number;
  minimum_price: number;
  vat_rate_id: number;
  vat_rate: Vatrate;
  country_id: number;
  country: Country;
  currency_id: number;
  currency: Currency;
  is_default_price: boolean;
  effective_from: string;
  effective_to: string;
  newDefault: boolean;

  constructor(id: number,
              product_id: number,
              price: number,
              minimum_price: number,
              vat_rate_id: number,
              country_id: number,
              currency_id: number,
              is_default_price: boolean,
              effective_from: string,
              effective_to: string,
              newDefault = false
  ) {
    this.id = id;
    this.product_id = product_id;
    this.price = price;
    this.minimum_price = minimum_price;
    this.vat_rate_id = vat_rate_id;
    this.country_id = country_id;
    this.currency_id = currency_id;
    this.is_default_price = is_default_price;
    this.effective_from = effective_from;
    this.effective_to = effective_to;
    this.newDefault = newDefault;
  }
}
