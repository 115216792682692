import {Injectable} from '@angular/core';
import {GetApiReply} from '../../../_models/get-api-reply.model';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SalestrackApiConfService} from '../salestrackApi-conf.service';

@Injectable({
    providedIn: 'root'
})

export class ProductCategoriesServiceV2 {

    constructor(
        private http: HttpClient,
        private configService: SalestrackApiConfService
    ) {
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error); // Replace with logging service later.
            console.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }

    getHttpOptions() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }),
            observe: 'response' as 'body'
        };
    }

    getProductCategories(optionalParams = '', pageIndex = null) {
        let httpOptions = this.getHttpOptions();
        if (pageIndex && pageIndex === 1) {
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
                    'Prefer': 'count=exact'
                }),
                observe: 'response' as 'body'
            };
        }
        return this.http.get<GetApiReply>(
            `${this.configService.getServerAddress()}product_categories?${optionalParams}`,
            // @ts-ignore
            httpOptions
        ).pipe(
            tap(res => {
                return {
                    // @ts-ignore
                    data: res.body,
                    // @ts-ignore
                    headers: res.headers,
                };
            }),
        );
    }

    createProductCategories(new_product_prices) {
        const httpOptions = this.getHttpOptions();
        return this.http.post<GetApiReply>(
            `${this.configService.getServerAddress()}product_categories`,
            new_product_prices,
            // @ts-ignore
            httpOptions
        ).pipe(
            tap(res => {
                return {
                    // @ts-ignore
                    data: res.body,
                    // @ts-ignore
                    headers: res.headers,
                };
            })
        );
    }

    updateProductCategories(updated_product_prices, id) {
        const httpOptions = this.getHttpOptions();
        return this.http.patch<GetApiReply>(
            `${this.configService.getServerAddress()}product_categories?id=eq.${id}`,
            updated_product_prices,
            // @ts-ignore
            httpOptions
        ).pipe(
            tap(res => {
                return {
                    // @ts-ignore
                    data: res.body,
                    // @ts-ignore
                    headers: res.headers,
                };
            })
        );
    }

    deleteProductPrices(id) {
        if (isNaN(id)) {
            return null;
        }
        const httpOptions = this.getHttpOptions();
        return this.http.delete<GetApiReply>(
            `${this.configService.getServerAddress()}product_categories?id=eq.${id}`,
            httpOptions
        ).pipe(
            tap(res => {
                return {
                    // @ts-ignore
                    data: res.body,
                    // @ts-ignore
                    headers: res.headers,
                };
            })
        );
    }
}
