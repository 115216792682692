export class ProductCategory {
  id: any;
  name: string;
  master_product_category_id: number;
  master_product_category: ProductCategory;

  constructor(id: any, name: string, master_product_category_id: number) {
    this.id = id;
    this.name = name;
    this.master_product_category_id = master_product_category_id;
  }
}
