import {Bank} from '../bank.model';
import {Currency} from './currency.model';

export class BankAccount {
  id: number;
  name: string;
  bank_id: number;
  bank: Bank;
  sort_code: string;
  account_number: string;
  iban: string;
  bic: string;
  currency_id: number;
  currency: Currency;

  constructor(id: number, name: string, bank_id: number, sort_code: string,
              account_number: string, iban: string, bic: string, currency_id: number) {
    this.id = id;
    this.name = name;
    this.bank_id = bank_id;
    this.sort_code = sort_code;
    this.account_number = account_number;
    this.iban = iban;
    this.bic = bic;
    this.currency_id = currency_id;
  }
}
